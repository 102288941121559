// General Utilites
//

import moment from "moment";
import { Moment } from "moment";
import { UoM } from "../lib/misc";
import { TenantServiceDto } from "../app-services/tenantServices";
import { ServiceAttributes } from "../models/Service";
import _ from "lodash";
import { ModelId } from "../lib/app-services";

// Loads a the list of UoM adding a displayName attribute to better identify the UoM
// async function loadUnitsOfMeasurement(url=`/api/uom`) {
//     let uomList = await $.ajax({ url: url })
//     uomList.forEach(el => el.displayName = `${el.singular} (${el.abbr})`)

//     return uomList
// }

// // Loads a list of costs centers
// async function loadCostCenters() {
//     return await $.ajax({ url: `/api/tenants/${currentTenantId}/cost-centers` })
//     // .then(data => costCentersList = data)
//     // .catch(error => {
//     //     setAlerts('danger', 'Error loading Cost Centers. ' + error.statusText + ': ' + error.responseText)
//     // })
// }

// Loads a list of Canadian Provinces codes
export const CANADIAN_PROVINCES = [
    {
        code: "AB",
        name: "Alberta",
    },
    {
        code: "BC",
        name: "British Columbia",
    },
    {
        code: "MB",
        name: "Manitoba",
    },
    {
        code: "NB",
        name: "New Brunswick",
    },
    {
        code: "NL",
        name: "Newfoundland and Labrador",
    },
    {
        code: "NS",
        name: "Nova Scotia",
    },
    {
        code: "NT",
        name: "Northwest Territories",
    },
    {
        code: "NU",
        name: "Nunavut",
    },
    {
        code: "ON",
        name: "Northwest Territories",
    },
    {
        code: "PE",
        name: "Prince Edward Island",
    },
    {
        code: "QC",
        name: "Quebec",
    },
    {
        code: "SK",
        name: "Saskatchewan",
    },
    {
        code: "YT",
        name: "Yukon",
    },
];

// Get a list of relevant UoM based on the selected tenant service
//
// If we are passed the serviceId param, serviceUomAbbr will be ignored and servicesList must not be null
export function getUoMFilteredList(
    args: {
        uomList: UoM[];
        // Instead of using TenantService or any of those types, I'm using a generic object
        // to be able to use this function with other types of objects that have a serviceId and serviceUomAbbr
        servicesList?: Pick<ServiceAttributes, "id" | "unitOfMeasurement">[];
        serviceId?: number;
        serviceUomAbbr?: string;
    } = {
        uomList: [],
    }
): UoM[] {
    let { uomList, servicesList, serviceId, serviceUomAbbr } = args;

    if (serviceId) {
        const selectedService = servicesList?.find((srv) => serviceId == srv.id);
        serviceUomAbbr = selectedService?.unitOfMeasurement;
    }

    const selectedServiceUom = uomList.find((uom) => serviceUomAbbr == uom.abbr);
    return uomList.filter((uom) => selectedServiceUom?.measure == uom.measure);
}

// // Get a list of relevant UoM based on the selected tenant service
// //
// // I'm using this version of the function instead of the one other one as this is
// // more specific (we already have the tenant service UoM when calling this function. In the other
// // cases, we only have the tenant service ID at the time of calling utils.js/getUoMFilteredList() )
// //
// function getUoMFilteredList2(serviceUomAbbr, uomList) {
//     let selectedServiceUom = uomList.find(uom => serviceUomAbbr == uom.abbr)
//     return uomList.filter(uom => selectedServiceUom.measure == uom.measure)
// }

// Returns an array of dates between the two dates
//
export function getDateRange(startDate: string | Date | Moment, endDate: Date | Moment): Moment[] {
    const m_startDate = moment.utc(startDate).hours(0).minutes(0).seconds(0).milliseconds(0);
    const m_endDate = moment.utc(endDate).hours(0).minutes(0).seconds(0).milliseconds(0);

    const now = m_startDate,
        dates: Moment[] = [];

    while (now.isSameOrBefore(m_endDate)) {
        dates.push(moment(now));
        now.add(1, "days");
    }
    return dates;
}

// Datetimepicker doesn't allow to init the internal moment object in UTC mode. This means that in this case, where
// we're not display the time picker (only the date), the time will be set using the current machine time and time zone,
// and sometimes this shifts the selected day of the month when this moment object is converted to UTC.
// As a workaround, we're manually creating another moment object (toDate) and setting the date elements independently so that we can ignore the time
// part in the datetimepicker moment object
//

// This function gets the entered date in a give datetimepicker component.
// When max is true, the time portion of the result will be set to 23:59:59.999. Otherwise, 0
// will be used
export function getDatetimePickerUTCDate(
    datetimepickerDiv: string,
    datetimepickerInput: string,
    max: boolean = false
    // format = "YYYY-MM-DD"
): Moment {
    const date = $(datetimepickerDiv).datetimepicker("date") as Moment;

    if (date) {
        if (max) {
            date.hours(23).minutes(59).seconds(59).milliseconds(999);
        } else {
            date.hours(0).minutes(0).seconds(0).milliseconds(0);
        }

        date.utc(true);
    }

    return date;

    // const d = moment.utc($(datetimepickerInput).val());

    // let result = null;
    // if ($(datetimepickerInput).val()) result = moment.utc([d.year(), d.month(), d.date(), 0, 0, 0, 0]);

    // if (max && result) result.hours(23).minutes(59).seconds(59).milliseconds(999);

    // return result;
}

// function getDatetimePickerDate(datetimepickerDiv, min = true, max = false) {
//     let d = $(datetimepickerDiv).datetimepicker('date')
// }

// // Render Associated Cost Center  (METERS view)
// //
// function aCC_toString(meter) {
//     const cell = [];
//     for (const aCC of meter) {
//         cell.push(`<span class="text-info ">${aCC.costCenter.name}</span>: ${aCC.percentage}%`);
//     }

//     return cell.join("&nbsp&nbsp|&nbsp&nbsp</span>");
// }
// Render Associated Cost Center  (METERS view)
//
export function aCC_toString(
    costCentersListWithACC: { name: string; AssociatedCostCenter: { percentage: number } }[]
): string {
    const cell = [];
    for (const cc of costCentersListWithACC) {
        cell.push(`<span class="text-info ">${cc.name}</span>: ${cc.AssociatedCostCenter.percentage}%`);
    }

    return cell.join("&nbsp&nbsp|&nbsp&nbsp</span>");
}

// Reporting Utils

export type MessageDisplayData = {
    messages: string[];
    service: {
        name: string;
    };
};

type DisplayMessagesData = {
    messages: string[];
    service?: {
        name: string;
    };
};
export function displayMessages(
    mainContainer: string,
    msgContainer: string,
    data: DisplayMessagesData | DisplayMessagesData[]
): void {
    let html = "<ul>";

    if (data instanceof Array) {
        for (const entry of data) {
            for (const msg of _.uniq(entry.messages)) {
                html += entry.service
                    ? `<li><strong>${entry.service.name}</strong>: ${msg}</li>`
                    : `<li>${msg}</li>`;
            }
        }
    } else {
        for (const msg of _.uniq(data.messages)) {
            html += data.service
                ? `<li><strong>${data.service.name}</strong>: ${msg}</li>`
                : `<li>${msg}</li>`;
        }
    }

    html += "</ul>";

    if (html.length > 9) {
        // did we added text to this element?
        $(msgContainer).html(html);
        $(mainContainer).prop("hidden", false);
    } else {
        $(mainContainer).prop("hidden", true);
    }
}

// function formatNumber(num) {
//     return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
// }

export function formatNumber(num: number | string, decimals: number = 2): string {
    if ("number" !== typeof num && "string" !== typeof num) {
        throw Error("Num parameter must be a Number or a String");
    }

    num = parseFloat(num as string); // parseFloat does support number and string, but TS is compaining, so... cast it
    if (isNaN(num)) {
        throw Error("Num is not a valid number");
    }

    const n = num.toFixed(decimals).split(".");

    n[0] = n[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    return n.join(".");
}

// Return an object containing theme-related properties (relative to Bootstrap)
//
export type Theme = {
    colors: {
        primary: string;
        secondary: string;
        success: string;
        info: string;
        warning: string;
        danger: string;
        light: string;
        dark: string;
    };
};

export function getTheme(): Theme {
    const style = getComputedStyle(document.body);
    const THEME = {
        colors: {},
    } as Theme;

    THEME.colors.primary = style.getPropertyValue("--primary");
    THEME.colors.secondary = style.getPropertyValue("--secondary");
    THEME.colors.success = style.getPropertyValue("--success");
    THEME.colors.info = style.getPropertyValue("--info");
    THEME.colors.warning = style.getPropertyValue("--warning");
    THEME.colors.danger = style.getPropertyValue("--danger");
    THEME.colors.light = style.getPropertyValue("--light");
    THEME.colors.dark = style.getPropertyValue("--dark");

    return THEME;
}

export const CHARTS_COLORS = {
    red: "rgb(255, 99, 132)",
    blue: "rgb(54, 162, 235)",
    orange: "rgb(255, 159, 64)",
    green: "rgb(75, 192, 192)",
    yellow: "rgb(255, 205, 86)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
};

export function initPage() {
    $("li.active").removeClass("active");
    const viewName = $("#globals-view-name").val();
    $("#nav-" + viewName).addClass("active");
}

///////
