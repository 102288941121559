// Note: added this to avoid the "Duplicate function implementation" error.

import { initPage } from "../utils";

$(document).ready(() => {
    initPage();
    init();
});

// TODO: check if there is a better way to do this
function init() {}
